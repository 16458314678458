import $ from 'jquery';

window.jQuery = $;
window.$ = $;

// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";

import slick from "slick-slider";

initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// home__slider init
$('.section__slider').slick({
  infinite: true,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: true,
  dots: true
});

// home__scroller init
$('.scroller').slick({
  infinite: true,
  centerMode: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1500,
  arrows: false,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});

// home__scroller init
$('.services__slider').slick({
  infinite: true,
  centerMode: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true
      }
    }
  ]
});

// home__clients init
$('.clients').slick({
  infinite: true,
  centerMode: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1500,
  arrows: false,
  variableWidth: false,
  centerPadding: '40px',
});


$('.slider-has-navs').slick({
  autoplay: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  arrows: true,
  dots: false,
  asNavFor: '.slider-nav-thumbnails',
});

$('.slider-nav-thumbnails').slick({
  autoplay: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  asNavFor: '.slider-has-navs',
  dots: true,
  focusOnSelect: true,
  variableWidth: true
});



/**
 * Contact Form
 */
function contactForm() {

  $("#contactForm").submit(function (event) {
    // Prevent the default form submission
    event.preventDefault();

    // Serialize the form data
    var formData = $(this).serialize();

    // Perform the AJAX request
    $.ajax({
      type: "POST",
      url: "contact.php", // Add the URL to your PHP script
      data: formData,
      success: function (response) {
        // Handle successful response
        $(".contact-result").html("<div class='alert alert-success'>" + response + "</div>");
      },
      error: function () {
        // Handle error response
        $(".contact-result").html("<div class='alert alert-danger'>There was an error sending your message. Please try again later.</div>");
      }
    });
  });
}

/**
 * Quote Form
 */
function quoteForm() {

  let currentStep = 1;

  $('#quoteForm').hide();

  // open the popup
  $('#quoteButton').on('click', function () {
    $('#quoteForm').show();
  });

  // clicking on the close button
  $('#quoteForm .btn-close').on('click', function () {
    $('#quoteForm').hide();
  });

  $(".next-step").click(function () {
    $("#step" + currentStep).hide();
    currentStep++;
    $("#step" + currentStep).show();
  });

  $(".prev-step").click(function () {
    $("#step" + currentStep).hide();
    currentStep--;
    $("#step" + currentStep).show();
  });

  $("#getQuoteForm").submit(function (event) {
    event.preventDefault();

    const formData = $(this).serialize();

    $.ajax({
      type: "POST",
      url: "quote.php", // Change to your server-side script URL
      data: formData,
      success: function (response) {
        $(".quote-result").html("<div class='alert alert-success'>" + response + "</div>");
      },
      error: function () {
        $(".quote-result").html("<div class='alert alert-danger'>There was an error sending your quote request. Please try again later.</div>");
      }
    });
  });

}

$(document).ready(function (e){
  quoteForm();
  contactForm();
});

// Your app code
console.log(`Hello ${process.env.HELLO}`);
